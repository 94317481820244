import { Controller } from "stimulus";
import {enter, leave} from 'el-transition';

export default class extends Controller {
  static targets = ["alert"]
  
  closeAlert(event) {
    const alertClicked = this.alertTarget.contains(event.target)

    if (alertClicked) {
      leave(this.alertTarget)
    }
  }


}