import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'spinner', 'text' ]
  static classes = [ 'spin' ]

  connect() {
    console.log("Connected Loading Controller")
  }

  spinner(event) {
    event.preventDefault()
    this.spinnerTarget.classList.remove("hidden")
    this.textTarget.textContent = "Generating PDF"
    setTimeout(() => {  window.location = event.target.parentNode.href; }, 100)
    
  }

}