import {Controller} from "stimulus";
// import the enter leave functions
import {enter, leave} from 'el-transition';

export default class extends Controller {
    static targets = ["menu", "button"]

    // call the enter and leave functions
    toggleMenu() {
        if(this.menuTarget.classList.contains('hidden')) {
            enter(this.menuTarget)
        } else {
            leave(this.menuTarget)
        }
    }
    
    hideMenu(event) {
      const buttonClicked = this.buttonTarget.contains(event.target)
  
      if (!buttonClicked) {
        leave(this.menuTarget)
      }
    }


}