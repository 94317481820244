// src/controllers/gene_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['add_tag', 'tagtemplate']

  test(event) {
    console.log("TEST")
  }

  add_tag(event) {
    event.preventDefault()  
    var content = this.tagtemplateTarget.innerHTML
    this.add_tagTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_tag(event) {
    console.log("Remove")
    event.preventDefault()
    let item = event.target.closest(".tag")
    item.remove()
  }

}
